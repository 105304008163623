import { createProxyConfig } from '@Components/storeComponents';

Ext.namespace('edi.selectors');
edi.selectors = new (function () {
	this.utils = {};
	this.fieldControls = {};
	this.defaults = {
		//company fields
		fns_id: {
			title: 'company.fns.id',
			name: 'fns_id',
			emptyText: ''
		},
		company_name: {
			title: 'company.name',
			name: 'company_name',
			emptyText: 'company.name.comment',
			maxLength: 175
		},
		company_gln: {
			title: 'company.gln',
			name: 'company_iln',
			emptyText: 'company.gln',
			maskRe: /\d/i,
			allowBlank: false,
			regex: edi.constants.VALIDATORS.GLN
		},
		company_inn_kpp: {
			type: 'fieldsBlock',
			panelConf: {
				title: 'company.inn.kpp',
				layout: {
					type: 'grid',
					gap: 16,
					area: [[6, 6]]
				}
			},
			fields: {
				inn: {
					withoutBlock: true,
					emptyText: 'company.inn',
					maskRe: /\d/i,
					columnWidth: 0.515,
					order: 10,
					regex: edi.constants.VALIDATORS.INN,
					name: 'company_inn',
					regexText: 'company.inn.error',
					allowBlank: false
				},
				kpp: {
					withoutBlock: true,
					emptyText: edi.i18n.getMessage('company.kpp'),
					maskRe: /\d/i,
					columnWidth: 0.85,
					order: 20,
					regex: edi.constants.VALIDATORS.KPP,
					name: 'company_kpp',
					regexText: edi.i18n.getMessage('company.kpp.error'),
					allowBlank: false
				}
			}
		},
		company_inn: {
			title: 'column.tax.id',
			emptyText: 'company.inn',
			maskRe: /\d/i,
			regex: edi.constants.VALIDATORS.INN,
			name: 'company_inn',
			regexText: 'company.inn.error'
		},
		company_kpp: {
			title: 'column.org.kpp',
			emptyText: '',
			maskRe: /\d/i,
			regex: edi.constants.VALIDATORS.KPP,
			name: 'company_kpp',
			regexText: 'company.kpp.error'
		},
		company_register_number: {
			title: 'column.register.number',
			emptyText: 'column.register.number',
			maskRe: /\d/i,
			regex: edi.constants.VALIDATORS.OGRN,
			name: 'company_register_number',
			regexText: 'company.ogrn.error'
		},
		company_reg_nr: {
			title: 'company.util.reg.nr',
			name: 'company_util_reg_nr',
			emptyText: 'company.util.reg.nr',
			maxLength: 35,
			maskRe: /\d/i
		},
		company_okpo: {
			name: 'company_okpo',
			title: 'company.okpo',
			emptyText: 'company.okpo',
			regex: edi.constants.VALIDATORS.OKPO,
			maskRe: /\d/i
		},
		company_okonh: {
			name: 'company_okonh',
			title: 'company.okonh',
			emptyText: 'company.okonh',
			maxLength: 5
		},
		company_org_form: {
			name: 'company_org_form',
			title: 'company.org.form',
			emptyText: 'company.org.form',
			maxLength: 255
		},
		//individual fields
		individual_inn: {
			name: 'individual_inn',
			title: 'company.inn.individual',
			emptyText: 'company.inn.individual.error',
			maskRe: /\d/i,
			regex: edi.constants.VALIDATORS.INDI_INN,
			regexText: 'company.inn.individual.error',
			allowBlank: true
		},
		individual_lastname: {
			name: 'individual_lastname',
			title: 'last.name',
			emptyText: 'company.last.name.comment',
			allowBlank: false
		},
		individual_name: {
			name: 'individual_firstname',
			title: 'first.name',
			emptyText: 'company.first.name.comment',
			allowBlank: false
		},
		individual_okpo: {
			name: 'company_okpo',
			title: 'company.okpo',
			emptyText: 'company.okpo',
			maskRe: /\d/i,
			regex: edi.constants.VALIDATORS.OKPO,
			allowBlank: false
		},
		individual_patronymicname: {
			name: 'individual_patronymicname',
			title: 'patronymic.name',
			emptyText: 'company.patronymic.name.comment'
		},
		individual_certificateRegistrationIP: {
			name: 'individual_certificateRegistrationIP',
			title: 'individual.certificateRegistration',
			emptyText: 'company.certificateRegistrationIP.comment'
		},
		structural_subdivision: {
			title: 'company.structuralSubdivision',
			name: 'structural_subdivision',
			emptyText: 'company.structuralSubdivision',
			order: 100,
			maxLength: 1000
		},

		//Address fields
		category: {
			type: 'combo',
			title: 'company.type.selector',
			disabled: function () {
				return false;
			},
			fieldConfig: {
				store: 'initAddressCategoryStore',
				name: 'addr_category',
				valuesCache: {},
				saveAddrToCache: function (org, type) {
					if (!org || !type) {
						return;
					}
					let combo = this;
					let companySelector = combo.modalInstance;
					let converter = companySelector?.processValues
						? companySelector.processValues
						: edi.converters.convertOrgToPartie;

					//set only one addr to org, because of internal logic of converter - it tries to find any address
					let clonedOrg = Ext.clone(org);
					let addr = edi.utils.getAddressFromOrganization(clonedOrg, type);
					if (addr) {
						clonedOrg.addresses = [addr];
						combo.valuesCache[type] = {};
						Object.entries(converter(clonedOrg)).forEach(([key, val]) => {
							combo.valuesCache[type][key] = {
								value: val,
								disabled: undefined
							};
						});
					}
				},
				listeners: {
					afterrender: function (combo) {
						//try to find selected organization
						let companySelector = combo.modalInstance;
						let selectedOrg = Ext.clone(companySelector?.selectedOrg);
						if (!selectedOrg && companySelector?.selectedOrgValues?.id) {
							selectedOrg = edi.utils.getOrg({ orgId: companySelector.selectedOrgValues.id });
						}
						//init cached values with values from organization card (if it exists)
						if (selectedOrg?.addresses?.length > 0) {
							[
								edi.constants.ADDRESS_TYPES.LEGAL,
								edi.constants.ADDRESS_TYPES.REAL,
								edi.constants.ADDRESS_TYPES.POSTAL
							].forEach((type) => combo.saveAddrToCache(selectedOrg, type));
						}

						let val = combo.getValue();
						combo.setDisabled(val === edi.constants.ADDRESS_TYPES.LEGAL);
					},
					change: function (combo, newVal, oldVal) {
						let tab = combo.up('panel[tab]');
						let addressTabFields = tab?.query('field');
						if (!addressTabFields) {
							return;
						}
						//save address values to cache and clear it
						if (oldVal) {
							combo.valuesCache[oldVal] = {};
							addressTabFields.forEach((f) => {
								if (f !== combo) {
									combo.valuesCache[oldVal][f.name] = {
										value: f.getValue(),
										disabled: f.isDisabled()
									};
									f.setValue();
									f.enable();
								}
							});
						}

						//retrieve values from cache if they are exists and address type was changed
						let cachedValues = combo.valuesCache[newVal] || {};
						if (newVal && newVal !== oldVal && Object.keys(cachedValues).length > 0) {
							addressTabFields.forEach((f) => {
								if (f !== combo && cachedValues[f.name]) {
									f.setValue(cachedValues[f.name]['value']);
									f.setDisabled(cachedValues[f.name]['disabled']);
								}
							});
						}
					}
				}
			}
		},
		categoryNew: {
			type: 'fieldsBlock',
			panelConf: {
				title: 'company.addresses.type',
				layout: {
					type: 'grid',
					area: [[3, 3, 3]]
				},
				valuesCache: {},
				saveAddrToCache: function (org, type) {
					if (!org || !type) {
						return;
					}
					let self = this;
					let companySelector = self.modalInstance;
					let converter = companySelector?.processValues
						? companySelector.processValues
						: edi.converters.convertOrgToPartie;

					//set only one addr to org, because of internal logic of converter - it tries to find any address
					let clonedOrg = Ext.clone(org);
					let addr = edi.utils.getAddressFromOrganization(clonedOrg, type);
					if (addr) {
						clonedOrg.addresses = [addr];
						self.valuesCache[type] = {};
						Object.entries(converter(clonedOrg)).forEach(([key, val]) => {
							self.valuesCache[type][key] = {
								value: val,
								disabled: undefined
							};
						});
					}
				},
				listeners: {
					afterrender: (fieldsBlock) => {
						//try to find selected organization
						let companySelector = fieldsBlock.modalInstance;
						let selectedOrg = Ext.clone(companySelector?.selectedOrg);
						if (!selectedOrg && companySelector?.selectedOrgValues?.id) {
							selectedOrg = edi.utils.getOrg({ orgId: companySelector.selectedOrgValues.id });
						}
						//init cached values with values from organization card (if it exists)
						if (selectedOrg?.addresses?.length > 0) {
							[
								edi.constants.ADDRESS_TYPES.LEGAL,
								edi.constants.ADDRESS_TYPES.REAL,
								edi.constants.ADDRESS_TYPES.POSTAL
							].forEach((type) => fieldsBlock.saveAddrToCache(selectedOrg, type));
						}

						const currentType = companySelector.fieldValues.addr_category;
						const radioBtns = fieldsBlock.getItems();
						radioBtns.forEach((radio) => {
							if (radio.inputValue === currentType) {
								radio.setValue(true);
							}
							if (currentType === edi.constants.ADDRESS_TYPES.LEGAL) {
								radio.setDisabled(true);
							}
						});
					}
				},
				onRadioSwitch: function (radio, newType) {
					const fieldsBlock = radio.ownerCt;
					const currentType = fieldsBlock.selectedAddrType;
					let tab = radio.up('panel[tab]');
					let addressTabFields = tab?.query('field');
					if (!addressTabFields) {
						return;
					}
					fieldsBlock.selectedAddrType = newType;
					//save address values to cache and clear it
					if (currentType) {
						fieldsBlock.valuesCache[currentType] = {};
						addressTabFields.forEach((f) => {
							if (f !== radio) {
								fieldsBlock.valuesCache[currentType][f.name] = {
									value: f.getValue(),
									disabled: f.isDisabled()
								};
								f.setValue();
								f.enable();
							}
						});
					}

					//retrieve values from cache if they are exists and address type was changed
					let cachedValues = fieldsBlock.valuesCache[newType] || {};
					if (newType && newType !== currentType && Object.keys(cachedValues).length > 0) {
						addressTabFields.forEach((f) => {
							if (f !== radio && cachedValues[f.name]) {
								f.setValue(cachedValues[f.name]['value']);
								f.setDisabled(cachedValues[f.name]['disabled']);
							}
						});
					}
				}
			},
			fields: {
				lagal: {
					type: 'radio',
					name: 'addr_category',
					inputValue: edi.constants.ADDRESS_TYPES.LEGAL,
					title: 'short.address.type.LEGAL',
					listeners: {
						change: (radio, newVal) => {
							if (newVal) {
								radio.ownerCt.onRadioSwitch(radio, radio.inputValue);
							}
						}
					}
				},
				real: {
					type: 'radio',
					name: 'addr_category',
					inputValue: edi.constants.ADDRESS_TYPES.REAL,
					title: 'short.address.type.REAL',
					listeners: {
						change: (radio, newVal) => {
							if (newVal) {
								radio.ownerCt.onRadioSwitch(radio, radio.inputValue);
							}
						}
					}
				},
				postal: {
					type: 'radio',
					name: 'addr_category',
					inputValue: edi.constants.ADDRESS_TYPES.POSTAL,
					title: 'short.address.type.POSTAL',
					listeners: {
						change: (radio, newVal) => {
							if (newVal) {
								radio.ownerCt.onRadioSwitch(radio, radio.inputValue);
							}
						}
					}
				}
			}
		},
		region: {
			type: 'combo',
			title: 'company.region',
			hidden: function (org) {
				return !org.addr_country || org.addr_country !== edi.constants.DEFAULT.COUNTRY;
			},
			fieldConfig: {
				name: 'addr_region_code',
				displayField: 'display_name',
				store: 'initRegionsStore',
				listeners: {
					change: function (combo, value) {
						var companySelector = combo.modalInstance,
							fieldValues = companySelector ? companySelector.fieldValues : null;

						if (fieldValues) {
							fieldValues['addr_region_name'] = value ? edi.utils.getRegion(value).name : '';
						}
					},
					afterrender: function (cmp) {
						let countryCombo = cmp.up('form')?.down('[name=addr_country]');
						if (countryCombo?.isDisabled()) {
							cmp.setDisabled(true);
						}
					}
				}
			}
		},
		area: {
			name: 'addr_rus_county',
			title: 'company.county',
			emptyText: 'company.county.location'
		},
		locality: {
			name: 'addr_rus_community',
			title: 'company.community',
			emptyText: 'company.community.location'
		},
		city: {
			name: 'addr_city',
			title: 'company.city',
			emptyText: 'company.city.location'
		},
		zip: {
			name: 'addr_zip',
			title: 'company.zip',
			emptyText: 'company.zip.postal',
			regex: edi.constants.VALIDATORS.ZIP,
			maxLength: 6
		},
		street: {
			name: 'addr_street',
			title: 'company.street',
			emptyText: 'company.street.comment'
		},
		home: {
			name: 'addr_home',
			title: 'company.home',
			emptyText: 'company.home.comment'
		},
		block: {
			name: 'addr_rus_block',
			title: 'company.address.block',
			emptyText: 'company.address.block.location'
		},
		addInf: {
			name: 'addr_addInf',
			title: 'company.address.addInf',
			normalizeValue: function (selector, fieldConf, value) {
				if (fieldConf.maxLength && typeof value === 'string' && value.length > fieldConf.maxLength) {
					return value.slice(0, fieldConf.maxLength);
				} else {
					return value;
				}
			}
		},
		flat: {
			name: 'addr_flat',
			title: 'company.flat',
			emptyText: 'company.flat.location'
		},
		foreign_address_text: {
			title: 'company.foreign.address.text',
			labelConf: {
				margin: 0
			},
			isTextarea: true,
			allowBlank: false,
			emptyText: 'company.foreign.address.text.info',
			maxLength: 255,
			name: 'addr_for_text'
		},
		full_address: {
			title: 'company.address',
			name: 'address',
			maxLength: 255,
			allowBlank: false,
			isTextarea: true
		},

		//bank fields
		bank_acc: {
			name: 'bank_acc',
			title: 'company.bank.account',
			maxLength: 35
		},
		bank_corr_acc: {
			name: 'bank_corr_acc',
			title: 'company.bank.account.corr'
		},
		bank_id: {
			title: 'company.bank.id',
			emptyText: 'company.bank.id.comment',
			name: 'bank_id',
			invalidText: edi.i18n.getMessage('invalid.value.bank.id')
		},
		bank_name: {
			name: 'bank_name',
			title: 'company.bank.name'
		},
		bank_address: {
			name: 'bank_address',
			title: 'company.bank.address',
			maxLength: 255
		},

		//contact fields
		contact: {
			name: 'contact',
			title: 'company.contact',
			maxLength: 255
		},
		contact_name: {
			name: 'contact_name',
			title: 'company.contact.name',
			maxLength: 175
		},
		contact_phone: {
			name: 'contact_phone',
			title: 'company.contact.phone'
		},
		contact_fax: {
			name: 'contact_fax',
			title: 'company.contact.fax'
		},
		contact_email: {
			name: 'contact_email',
			title: 'company.contact.email'
		},
		contact_web: {
			name: 'contact_web',
			title: 'company.contact.web',
			maxLength: 175
		},
		contact_person: {
			name: 'contact_person',
			title: 'company.contact.person',
			minLength: 1,
			maxLength: 35
		},
		operator_name: {
			name: 'operator_name',
			title: 'company.operator.name',
			maxLength: 175
		},
		operator_phone: {
			name: 'operator_phone',
			title: 'company.operator.phone',
			maxLength: 35
		},
		operator_email: {
			name: 'operator_email',
			title: 'company.operator.email',
			maxLength: 175
		},
		representative_name: {
			name: 'representative_name',
			title: 'company.representative.name',
			maxLength: 175
		},
		representative_phone: {
			name: 'representative_phone',
			title: 'company.representative.phone',
			maxLength: 35
		},
		representative_email: {
			name: 'representative_email',
			title: 'company.representative.email',
			maxLength: 175
		},

		//Combo-boxes
		company_type_combo: {
			type: 'combo',
			title: 'company.type.selector',
			fieldConfig: {
				disabled: function (org) {
					return (
						!!org && (org.type === 'company' || org.type === 'individual' || org.type === 'legalForeigner')
					);
				},
				displayField: 'type',
				store: 'initCompanyTypeStore',
				valueField: 'id',
				name: 'type',
				listeners: {
					change: function (cmp) {
						edi.selectors.fieldControls.switchCompanyTypeBlocks(cmp);
					},
					afterRender: function (cmp) {
						edi.selectors.fieldControls.switchCompanyTypeBlocks(cmp);
					}
				}
			}
		},
		iso2_country_combo: {
			type: 'combo',
			title: 'company.foreign.address.country',
			fieldConfig: {
				disabled: function (org) {
					return !!((org && org.addr_country) || org.addr_country_name);
				},
				name: 'addr_country',
				store: 'initCountryStore',
				valueField: 'id',
				mandatory: true,
				listeners: {
					change: function (cmp) {
						edi.selectors.fieldControls.enableRegionField(cmp);
					},
					afterrender: function (field) {
						const addr_country = field.getValue();
						if (!addr_country) {
							const country = edi.stores.data.countries_full.find(function (item) {
								return item.iso_3 === edi.constants.DEFAULT.COUNTRY;
							});
							if (country && 'object' == typeof country) {
								field.setValue(country.iso_2);
							}
						}
					}
				}
			}
		},
		iso3_country_combo: {
			type: 'combo',
			title: 'company.foreign.address.country',
			fieldConfig: {
				store: 'initCountryFullStore',
				valueField: 'iso_number_3',
				name: 'addr_for_country',
				validator: function () {
					return !!this.getValue() || edi.i18n.getMessage('error.value.mandatory');
				}
			}
		},
		address_type_combo: {
			type: 'combo',
			title: 'company.address.type.selector',
			fieldConfig: {
				disabled: function (org) {
					return !!org && (org.address_type == 0 || org.address_type == 1);
				},
				value: function (org) {
					return org.address_type == 0 || org.address_type == 1 ? org.address_type : 0;
				},
				store: 'initAddressTypeStore',
				name: 'address_type',
				displayField: 'type',
				listeners: {
					change: function (cmp) {
						edi.selectors.fieldControls.switchAddressBlocks(cmp);
					},
					afterRender: function (cmp) {
						edi.selectors.fieldControls.switchAddressBlocks(cmp);
					}
				}
			}
		},
		bank_account_combo: {
			type: 'autocomplete',
			title: 'company.bank.account.select',
			hidden: function (org) {
				return !(org && org.id);
			},
			fieldConfig: {
				forceSelection: true,
				columnWidth: 0.7,
				valueField: 'number',
				displayField: 'number',
				disabled: false,
				queryParam: 'number',
				minChars: 0,
				disablePaging: true,
				store: {
					model: 'BANK_ACCOUNT',
					autoLoad: false,
					remoteSort: true,
					sortOnLoad: true,
					pageSize: 10,
					sorters: {
						property: 'number',
						direction: 'ASC'
					}
				},
				listeners: {
					select: function (cmp, records) {
						var bankFieldsMap = {
							bank_acc: 'number',
							bank_name: 'preferredOwnerName',
							bank_id: 'bik',
							bank_corr_acc: 'corrAccNumber'
						};
						edi.selectors.fieldControls.fillBankFields(cmp, records, undefined, bankFieldsMap);
					}
				}
			},
			onCreate: function (accPanel, org) {
				var combo = accPanel.down('combo');
				if (org && org.id && combo && combo.getStore()) {
					combo.getStore().setProxy(
						createProxyConfig({
							type: 'ajax',
							url: edi.utils.formatString(edi.rest.services.USER.BANK_ACCOUNTS.GET, {
								orgId: org.id
							})
						})
					);
				}
			}
		},
		bank_acc_select: {
			type: 'select',
			title: 'company.bank.account',
			name: 'bank_acc',
			allowBlank: true,
			fieldConfig: {
				valueField: 'number',
				noFilter: true,
				name: 'bank_acc',
				fieldName: 'bank_acc',
				isClearValue: true,
				modalTitle: 'company.bank.account.select',
				model: 'BANK_ACCOUNT',
				columns: 'bank_account',
				editable: true,
				isPreloadData: true,
				disablePaging: false,
				hideTriggerIfNoData: true,
				preloadConvertData: function (items) {
					if (items && items.length) {
						var elm = items[0];
						if (items.length === 1) {
							return elm;
						}
						for (var i = 0; i < items.length; i++) {
							var current = items[i];
							if (current.modifyDate > elm.modifyDate) {
								elm = current;
							}
						}
						return elm;
					}
					return null;
				},
				regex: edi.constants.VALIDATORS.BANK_ACC,
				afterSelect: function (selectedData) {
					var bankFieldsMap = {
						bank_acc: 'number',
						bank_name: 'preferredOwnerName',
						bank_id: 'bik',
						bank_corr_acc: 'corrAccNumber'
					};
					edi.selectors.fieldControls.fillBankFields(this, undefined, selectedData, bankFieldsMap);
				}
			},
			onCreate: function (accPanel, org) {
				var setUrlMethod;
				if ('function' == typeof accPanel) {
					setUrlMethod = accPanel;
				} else if (accPanel.items) {
					var select = accPanel.down('[name="bank_acc"]');
					if (select && 'function' == typeof select.setUrl) {
						setUrlMethod = select.setUrl;
					}
				}
				if (org && org.id && setUrlMethod) {
					setUrlMethod(
						edi.utils.formatString(edi.rest.services.USER.BANK_ACCOUNTS.GET, {
							orgId: org.id
						})
					);
				}
			}
		},
		vbox_panel: {
			type: 'panel',
			panelConf: {
				layout: 'anchor',
				defaults: {
					anchor: '100%'
				}
			}
		}
	};

	//Extended defaults for FNS_documents
	this.defaultsFNS = {
		main: {
			company_name: Object.assign({ allowBlank: false }, this.defaults.company_name),
			company_gln: this.defaults.company_gln,
			company_inn_kpp: this.defaults.company_inn_kpp,
			company_okpo: this.defaults.company_okpo,
			individual_gln: this.defaults.company_gln,
			individual_inn: this.defaults.individual_inn,
			individual_okpo: this.defaults.individual_okpo,
			individual_lastName: this.defaults.individual_lastname,
			individual_firstName: this.defaults.individual_name,
			individual_patronymicName: this.defaults.individual_patronymicname,
			certificateRegistrationIP: this.defaults.individual_certificateRegistrationIP,
			structural_subdivision: this.defaults.structural_subdivision
		},
		address: {
			zip: Ext.applyIf(
				{
					name: 'addr_rus_zip',
					regex: edi.constants.VALIDATORS.ZIP_RUSSIA
				},
				this.defaults.zip
			),
			region: Ext.merge(Ext.clone(this.defaults.region), {
				fieldConfig: {
					name: 'addr_rus_region',
					validator: function () {
						return !!this.getValue() || edi.i18n.getMessage('error.value.mandatory');
					}
				}
			}),
			area: this.defaults.area,
			city: Ext.applyIf({ name: 'addr_rus_city' }, this.defaults.city),
			locality: this.defaults.locality,
			street: Ext.applyIf({ name: 'addr_rus_street' }, this.defaults.street),
			home: Ext.applyIf({ name: 'addr_rus_home' }, this.defaults.home),
			block: this.defaults.block,
			flat: Ext.applyIf({ name: 'addr_rus_flat' }, this.defaults.flat),
			country: this.defaults.iso3_country_combo,
			foreignAddr: this.defaults.foreign_address_text
		},
		bank: {
			bank_account: this.defaults.bank_account_combo,
			bank_acc: this.defaults.bank_acc,
			bank_acc_select: this.defaults.bank_acc_select,
			bank_corr_acc: this.defaults.bank_corr_acc,
			bank_id: this.defaults.bank_id,
			bank_name: this.defaults.bank_name
		},
		contact: {
			phone: this.defaults.contact_phone,
			fax: this.defaults.contact_fax,
			email: this.defaults.contact_email
		}
	};

	/**
	 * Merge a copy of default conf with additional conf
	 * @param      defaultConfName      Field name from default fields
	 * @param      additionalConf       Additional field config
	 * @return {*}
	 */
	this.mergeConfigs = function (defaultConfName, additionalConf) {
		var defaultConf = Ext.clone(edi.utils.getObjectProperty(this.defaults, defaultConfName));
		return Ext.merge(defaultConf, additionalConf);
	};

	/**
	 * Creates modal config for legacy documents
	 * @param      additionalConf      Additional fields config
	 * @return     {Object}
	 */
	this.getLegacyModalConf = function (additionalConf) {
		var defaults = edi.selectors.defaults;
		var defaultConf = Ext.clone({
			main: {
				company_name: defaults.company_name,
				company_gln: defaults.company_gln,
				company_reg_number: defaults.company_reg_nr
			},
			address: {
				country: Ext.merge({}, defaults.iso2_country_combo, {
					fieldConfig: {
						mandatory: false,
						allowBlank: true
					}
				}),
				region: defaults.region,
				city: defaults.city,
				zip: defaults.zip,
				street: defaults.street
			},
			bank: {},
			contact: {
				operator_name: defaults.operator_name,
				operator_phone: defaults.operator_phone,
				operator_email: defaults.operator_email
			}
		});

		var result = Ext.merge(defaultConf, additionalConf);

		return {
			tabs: result
		};
	};

	/**
	 * Creates modal config for FNS documents
	 * @param      additionalConf      Additional fields config
	 * @param      mergeConfigForm     Merged fields config
	 * @return     {Object}
	 */
	this.getFNSModalConf = function (additionalConf, mergeConfigForm) {
		//Apply additional config to fields

		var fnsDefaults = Ext.clone(edi.selectors.defaultsFNS);
		Ext.merge(fnsDefaults, additionalConf);

		var vboxPanel = {
			layout: 'anchor',
			defaults: {
				anchor: '100%'
			}
		};

		var exlcudeTab = function (tabName) {
			//Exclude tab if pass null or undefined or false
			return additionalConf && additionalConf.hasOwnProperty(tabName) && !additionalConf[tabName];
		};

		if (fnsDefaults.main.individual_okpo) {
			fnsDefaults.main.individual_okpo.maxLength = 10;
			fnsDefaults.main.individual_okpo.minLength = 10;
			delete fnsDefaults.main.individual_okpo.regex;
		}
		if (fnsDefaults.main.company_okpo) {
			fnsDefaults.main.company_okpo.minLength = 8;
			fnsDefaults.main.company_okpo.maxLength = 8;
			delete fnsDefaults.main.company_okpo.regex;
		}
		if (mergeConfigForm?.main?.company_data?.fields?.okpo) {
			mergeConfigForm.main.company_data.fields.okpo.minLength = 8;
			mergeConfigForm.main.company_data.fields.okpo.maxLength = 8;
			delete mergeConfigForm.main.company_data.fields.okpo.regex;
		}
		//Create tabs structure from defaults & exclude tab if needed
		var result = {
			main: exlcudeTab('main')
				? null
				: Ext.merge(
						{
							company_type: Ext.clone(edi.selectors.defaults.company_type_combo),
							company_data: {
								type: 'container',
								blockId: 'company_data_block',
								panelConf: vboxPanel,
								fields: {
									company_name: fnsDefaults.main.company_name,
									company_gln: fnsDefaults.main.company_gln,
									company_inn_kpp: fnsDefaults.main.company_inn_kpp,
									company_okpo: fnsDefaults.main.company_okpo
								}
							},
							individual_data: {
								type: 'container',
								blockId: 'company_data_block',
								panelConf: vboxPanel,
								fields: {
									individual_gln: fnsDefaults.main.individual_gln,
									individual_inn: fnsDefaults.main.individual_inn,
									company_okpo: fnsDefaults.main.individual_okpo,
									registration_items: {
										type: 'fieldset',
										title: 'company.individual.data',
										panelConf: {
											layout: 'grid'
										},
										fields: {
											individual_lastName: fnsDefaults.main.individual_lastName,
											individual_firstName: fnsDefaults.main.individual_firstName,
											individual_patronymicName: fnsDefaults.main.individual_patronymicName,
											certificateRegistrationIP: fnsDefaults.main.certificateRegistrationIP
										}
									}
								}
							},
							structural_subdivision: fnsDefaults.main.structural_subdivision
						},
						mergeConfigForm !== undefined ? edi.utils.getObjectProperty(mergeConfigForm, 'main') : {}
				  ),
			address: exlcudeTab('address')
				? null
				: Ext.merge(
						{
							addressType: Ext.clone(edi.selectors.defaults.address_type_combo),
							rusAddress: {
								type: 'fieldset',
								title: 'company.address.header',
								panelConf: {
									layout: 'grid'
								},
								fields: {
									zip: fnsDefaults.address.zip,
									region: fnsDefaults.address.region,
									area: fnsDefaults.address.area,
									city: fnsDefaults.address.city,
									locality: fnsDefaults.address.locality,
									street: fnsDefaults.address.street,
									home: fnsDefaults.address.home,
									block: fnsDefaults.address.block,
									flat: fnsDefaults.address.flat
								}
							},
							foreignAddress: {
								type: 'fieldset',
								panelConf: {
									layout: 'grid'
								},
								title: 'company.address.foreign.header',
								fields: {
									country: fnsDefaults.address.country,
									foreignAddr: fnsDefaults.address.foreignAddr
								}
							}
						},
						mergeConfigForm !== undefined ? edi.utils.getObjectProperty(mergeConfigForm, 'address') : {}
				  ),
			bank: exlcudeTab('bank')
				? null
				: {
						bank_acc: fnsDefaults.bank.bank_acc_select,
						bank_corr_acc: fnsDefaults.bank.bank_corr_acc,
						bank_id: fnsDefaults.bank.bank_id,
						bank_name: fnsDefaults.bank.bank_name
				  },
			contact: exlcudeTab('contact')
				? null
				: {
						phone: fnsDefaults.contact.phone,
						fax: fnsDefaults.contact.fax,
						email: fnsDefaults.contact.email
				  }
		};

		return {
			tabs: result
		};
	};

	/**
	 * Creates modal config for FNS documents
	 * @param      additionalConf      Additional fields config
	 * @param      mergeConfigForm     Merged fields config
	 * @return     {Object}
	 */
	this.getGridFNSModalConf = function (additionalConf, mergeConfigForm) {
		//Apply additional config to fields

		let defaults = Ext.clone(edi.selectors.defaults);
		let fnsDefaults = Ext.clone(edi.selectors.defaultsFNS);
		Ext.merge(fnsDefaults, additionalConf);
		Ext.merge(defaults, additionalConf);

		const exlcudeTab = function (tabName) {
			//Exclude tab if pass null or undefined or false
			return additionalConf && additionalConf.hasOwnProperty(tabName) && !additionalConf[tabName];
		};

		//Create tabs structure from defaults & exclude tab if needed
		fnsDefaults.address.zip.width = 90;
		fnsDefaults.address.region.width = 304;
		if (fnsDefaults.main.individual_okpo) {
			fnsDefaults.main.individual_okpo.maxLength = 10;
			fnsDefaults.main.individual_okpo.minLength = 10;
			delete fnsDefaults.main.individual_okpo.regex;
		}
		if (fnsDefaults.main.company_okpo) {
			fnsDefaults.main.company_okpo.minLength = 8;
			fnsDefaults.main.company_okpo.maxLength = 8;
			delete fnsDefaults.main.company_okpo.regex;
		}
		if (mergeConfigForm?.main?.company_data?.fields?.okpo) {
			mergeConfigForm.main.company_data.fields.okpo.minLength = 8;
			mergeConfigForm.main.company_data.fields.okpo.maxLength = 8;
			delete mergeConfigForm.main.company_data.fields.okpo.regex;
		}
		//Create tabs structure from defaults & exclude tab if needed
		const result = {
			main: exlcudeTab('main')
				? null
				: Ext.merge(
						{
							tabConfig: {
								layout: 'auto'
							},
							company_type: defaults.company_type_combo,
							company_data: {
								type: 'container',
								blockId: 'company_data_block',
								fields: {
									company_type_fields_block: {
										type: 'fieldsBlock',
										containerConf: {
											layout: {
												type: 'grid',
												area: [8, 4, [4, 4], 8, 8]
											}
										},
										fields: {
											company_name: defaults.company_name,
											company_gln: defaults.company_gln,
											company_okpo: fnsDefaults.main.company_okpo,
											company_inn: defaults.company_inn,
											company_kpp: defaults.company_kpp,
											structural_subdivision: fnsDefaults.main.structural_subdivision
										}
									}
								}
							},
							individual_data: {
								type: 'container',
								blockId: 'company_data_block',
								fields: {
									individual_data_block: {
										type: 'fieldsBlock',
										containerConf: {
											layout: {
												type: 'grid',
												area: [[4, 4], 8, 12, 8]
											}
										},
										fields: {
											company_gln: fnsDefaults.main.individual_gln,
											individual_inn: fnsDefaults.main.individual_inn,
											company_okpo: fnsDefaults.main.individual_okpo,
											company_individual_data: {
												type: 'fieldset',
												title: 'company.individual.data',
												panelConf: {
													layout: {
														type: 'grid',
														area: [[4, 4, 4], 6]
													}
												},
												fields: {
													individual_lastName: fnsDefaults.main.individual_lastName,
													individual_firstName: fnsDefaults.main.individual_firstName,
													individual_patronymicName:
														fnsDefaults.main.individual_patronymicName,
													certificateRegistrationIP:
														fnsDefaults.main.certificateRegistrationIP
												}
											},
											structural_subdivision: fnsDefaults.main.structural_subdivision
										}
									}
								}
							}
						},
						mergeConfigForm !== undefined ? edi.utils.getObjectProperty(mergeConfigForm, 'main') : {}
				  ),
			address: exlcudeTab('address')
				? null
				: Ext.merge(
						{
							addressType: Ext.clone(edi.selectors.defaults.address_type_combo),
							rusAddress: {
								type: 'fieldset',
								title: 'company.address.header',
								panelConf: {
									layout: {
										type: 'grid',
										area: [[2, 6], 6, 6, 6, [6, 2, 2, 2]]
									}
								},
								fields: {
									zip: fnsDefaults.address.zip,
									region: fnsDefaults.address.region,
									area: fnsDefaults.address.area,
									city: fnsDefaults.address.city,
									locality: fnsDefaults.address.locality,
									street: fnsDefaults.address.street,
									home: fnsDefaults.address.home,
									block: fnsDefaults.address.block,
									flat: fnsDefaults.address.flat
								}
							},
							foreignAddress: {
								type: 'fieldset',
								title: 'company.address.foreign.header',
								panelConf: {
									layout: {
										type: 'grid',
										area: [8]
									}
								},
								fields: {
									country: fnsDefaults.address.country,
									foreignAddr: fnsDefaults.address.foreignAddr
								}
							}
						},
						mergeConfigForm !== undefined ? edi.utils.getObjectProperty(mergeConfigForm, 'address') : {}
				  ),
			bank: exlcudeTab('bank')
				? null
				: {
						bank_data: {
							type: 'container',
							layout: {
								type: 'grid',
								area: [[5, 4, 3], 8]
							},
							fields: {
								bank_acc: fnsDefaults.bank.bank_acc_select,
								bank_corr_acc: fnsDefaults.bank.bank_corr_acc,
								bank_id: fnsDefaults.bank.bank_id,
								bank_name: fnsDefaults.bank.bank_name
							}
						}
				  },
			contact: exlcudeTab('contact')
				? null
				: {
						bank_data: {
							type: 'container',
							layout: {
								type: 'grid',
								area: [[4, 4], 6]
							},
							fields: {
								phone: fnsDefaults.contact.phone,
								fax: fnsDefaults.contact.fax,
								email: fnsDefaults.contact.email
							}
						}
				  }
		};

		return {
			tabs: result
		};
	};

	/**
	 * Creates fieldsMap for Legacy-documents
	 * @param      prefix             FieldsMap prefix
	 * @param      options          include fields, exclude fields, additional map
	 * @return {*}
	 */
	this.utils.getLegacyFieldsMap = function (prefix, options) {
		options = options || {};
		var includeFields = options.includeFields || [],
			excludeFields = options.excludeFields || [],
			additionalMap = options.additionalMap || {};

		prefix += '.';

		var map = {
			company_name: prefix + 'Name',
			company_iln: prefix + 'ILN',
			company_util_reg_nr: prefix + 'UtilizationRegisterNumber',
			company_inn: prefix + 'TaxID',
			company_kpp: prefix + 'TaxRegistrationReasonCode',

			addr_category: prefix + 'Type',
			addr_country: prefix + 'Country',
			addr_zip: prefix + 'PostalCode',
			addr_locality: prefix + 'Locality',
			addr_block: prefix + 'Housing',
			addr_addInf: prefix + 'AdditionalInformation',
			addr_area: prefix + 'District',
			addr_region_name: prefix + 'State',
			addr_region_code: prefix + 'StateCode',
			addr_city: prefix + 'CityName',
			addr_street: prefix + 'StreetAndNumber',
			addr_home: prefix + 'HouseNumber',
			addr_flat: prefix + 'RoomNumber',

			license_type: prefix + 'License.Type',
			license_name: prefix + 'License.Name',
			license_series: prefix + 'License.Series',
			license_number: prefix + 'License.Number',
			license_issuing_authority: prefix + 'License.IssuingAuthority',
			license_date_of_issue: prefix + 'License.DateOfIssue',
			license_expiration_date: prefix + 'License.ExpirationDate'
		};

		var result = Ext.clone(map),
			i,
			fieldName;

		if (includeFields.length) {
			result = {};
			for (i = 0; i < includeFields.length; i++) {
				fieldName = includeFields[i];
				if (map.hasOwnProperty(fieldName)) {
					result[fieldName] = map[fieldName];
				}
			}
		} else if (excludeFields.length) {
			for (i = 0; i < excludeFields.length; i++) {
				fieldName = excludeFields[i];
				if (result.hasOwnProperty(fieldName)) {
					delete result[fieldName];
				}
			}

			edi.utils.clearEmptyValues(result);
		}

		return Object.assign(result, additionalMap);
	};

	/**
	 * Creates fieldsMap for FNS-documents
	 * @param prefixes
	 * @param additionalMap
	 * @return {*}
	 */
	this.utils.getFNSFieldsMap = function (prefixes, additionalMap) {
		var prefix = prefixes.general,
			company = prefix + prefixes.company + '.legalEntity.',
			rusAddress = prefix + prefixes.addressRU + '.',
			foreignAddress = prefix + prefixes.addressFOR + '.',
			individual = prefix + prefixes.company + '.individualPerson.',
			contact = prefix + prefixes.contact + '.';

		var map = {
			company_name: company + 'orgName',
			company_inn: company + 'tin',
			company_kpp: company + 'kpp',
			okpo: prefix + 'okpo',

			individual_inn: individual + 'tin',
			individual_lastname: individual + 'name.lastName',
			individual_firstname: individual + 'name.firstName',
			individual_patronymicname: individual + 'name.middleName',

			addr_rus_zip: rusAddress + 'index',
			addr_rus_region: rusAddress + 'regionCode',
			addr_rus_county: rusAddress + 'area',
			addr_rus_city: rusAddress + 'city',
			addr_rus_street: rusAddress + 'street',
			addr_rus_community: rusAddress + 'settlement',
			addr_rus_home: rusAddress + 'house',
			addr_rus_block: rusAddress + 'housing',
			addr_rus_flat: rusAddress + 'flat',

			addr_for_country: foreignAddress + 'countryCode',
			addr_for_text: foreignAddress + 'addrText',

			contact_phone: contact + 'tel',
			contact_fax: contact + 'fax',

			address_type: true,
			type: true
		};

		return Object.assign(map, additionalMap);
	};

	/**
	 * Show/hide block and his fields and set them enabled or disabled
	 * @param	block					{Object}	Fields block
	 * @param	enable					{Boolean}	True/false to show and enable fields
	 * @param	org						{Object}	Selected org of selector
	 * @param	isModalReadOnly			{Boolean}	True if modal is read-only
	 * @param	forceChangeFieldsMap	{Object}	Map with fields which must always be enabled to change
	 * @param	forceChangeAnyField		{boolean}	if true - allow change any field
	 */
	this.utils.enableFieldBlock = function (
		block,
		enable,
		org,
		isModalReadOnly,
		forceChangeFieldsMap,
		forceChangeAnyField
	) {
		var fields = block ? block.query('field') : [],
			field,
			fieldPanel;
		forceChangeFieldsMap = forceChangeFieldsMap || {};

		if (fields.length) {
			for (var i = 0; i < fields.length; i++) {
				field = fields[i];
				if (!field) {
					continue;
				}

				let fieldName = field.name;
				let orgHasValue = !!(org && org[fieldName]);
				let disabled =
					!enable ||
					isModalReadOnly ||
					(!forceChangeAnyField && !forceChangeFieldsMap.hasOwnProperty(fieldName) && orgHasValue);

				field.setDisabled(disabled);
				fieldPanel = field.ownerCt;
				if (fieldPanel) {
					fieldPanel.setVisible(enable);
				}
			}
		}

		if (block) {
			block.setVisible(enable);
		}
	};

	/**
	 * Switch main tab fields due to company type (LEGAL or INDIVIDUAL)
	 * @param companyTypeCombo
	 * @param customMethod
	 * @param setTypeValueMethod
	 */
	this.fieldControls.switchCompanyTypeBlocks = function (companyTypeCombo, customMethod, setTypeValueMethod) {
		var companySelector = companyTypeCombo.modalInstance,
			form = companySelector ? companySelector.formPanel : null,
			isModalReadOnly = edi.utils.getObjectProperty(companySelector, 'modalConf.readOnly') || false,
			forceChangeFieldsMap = edi.utils.getObjectProperty(companySelector, 'forceChangeFields') || {},
			forceChangeAnyField = companySelector && !!companySelector.forceChangeAnyField,
			fieldValues = companySelector?.selectedOrgValues || {},
			orgType = companyTypeCombo.getValue();

		if (!form) {
			return;
		}

		if (!orgType) {
			if ('function' == typeof setTypeValueMethod) {
				orgType = setTypeValueMethod(fieldValues);
			}
			(edi.selectors.orgConfig?.DEFAULT || []).forEach(function (obj) {
				var orgKey = Object.keys(obj)[0];
				var orgValues = obj[orgKey];
				if (!Array.isArray(orgValues)) {
					orgValues = [orgValues];
				}
				orgValues.forEach(function (orgValue) {
					if (fieldValues[orgValue]) {
						orgType = orgKey;
					}
				});
			});

			if (orgType) {
				companyTypeCombo.setValue(orgType);
			}
		}

		(edi.selectors.orgConfig?.DEFAULT || []).forEach(function (obj) {
			var orgKey = Object.keys(obj)[0];
			var orgValues = obj[orgKey];
			var companyName;
			if (!Array.isArray(orgValues)) {
				orgValues = [orgValues];
			}
			orgValues.forEach(function (orgValue) {
				var field = form.down(`[name="${orgValue}"]`);
				if (field) {
					companyName = field;
				}
			});

			if (companyName) {
				var companyData = companyName.up('[blockId="company_data_block"]');
				if (companyData) {
					edi.selectors.utils.enableFieldBlock(
						companyData,
						orgType === orgKey,
						fieldValues,
						isModalReadOnly,
						forceChangeFieldsMap,
						forceChangeAnyField
					);
				}
			}
		});

		if ('function' == typeof customMethod) {
			customMethod(form, orgType, companySelector);
		}

		form.isValid();
	};

	/**
	 * Show/hide region combo based on selected country
	 * @param combo
	 */
	this.fieldControls.enableRegionField = function (combo) {
		var russiaSelected = combo.getValue() === edi.constants.DEFAULT.COUNTRY,
			companySelector = combo.modalInstance,
			form = companySelector ? companySelector.formPanel : null;

		if (form) {
			var regionCombo = form.down('combo[name=addr_region_code]');
			if (regionCombo) {
				regionCombo.setDisabled(!russiaSelected);

				var panel = regionCombo.getFieldBlock();
				if (panel) {
					panel.setVisible(russiaSelected);
				}
			}
		}
	};
	/**
	 * Switch INN field validator based on selected country
	 * @param	{ExtComponent}	selector
	 * @param	{ExtComponent}	form
	 */
	this.fieldControls.updateInnField = function (selector, form) {
		const innField = form.down('[name="company_inn"]');
		if (!innField) {
			return;
		}
		if (innField.originalRegexSaved !== true) {
			innField.originalRegex = innField.regex ?? null;
			innField.originalRegexText = innField.regexText ?? null;
			innField.originalMaskRe = innField.maskRe;
			innField.originalRegexSaved = true;
		}
		const isBLR = selector.selectedOrg?.country === 'BLR';
		innField.regex = isBLR ? edi.constants.VALIDATORS.UNP_BLR_INN : innField.originalRegex;
		innField.maskRe = isBLR ? edi.constants.VALIDATORS.MASK_RE_UNP_BLR_INN : innField.originalMaskRe;
		innField.regexText = edi.i18n.getMessage(isBLR ? 'company.inn.error.blr' : innField.originalRegexText);
		innField.isValid();
		form.isValid();
	};
	/**
	 * Switch main tab fields due to company type (LEGAL or INDIVIDUAL)
	 * @param	bankAccountCombo     Bank account combo
	 * @param	records              Selected records
	 * @param	recordData           Selected record data
	 * @param	bankFieldsMap
	 */
	this.fieldControls.fillBankFields = function (bankAccountCombo, records, recordData, bankFieldsMap) {
		var fillFields = function (selectedData) {
			var input,
				companySelector = bankAccountCombo.modalInstance,
				form = companySelector ? companySelector.formPanel : null;

			if (!form) {
				return;
			}

			for (var i in bankFieldsMap) {
				if (bankFieldsMap.hasOwnProperty(i)) {
					input = form.down('textfield[name=' + i + ']');
					if (input) {
						input.setValue(selectedData ? selectedData[bankFieldsMap[i]] : '');
					}
				}
			}
		};
		if (recordData || (records && (records.data || records.length))) {
			var selectedData = recordData || (records.length ? records[0].getData() : records.getData());
			fillFields(selectedData);
		} else {
			fillFields();
		}
	};
	/**
	 * Switches bankNameField allowBlank when bankAcc changed
	 * @param	{Object}	opts
	 * @param	{Object}	opts.bankAccField
	 * @param	{String}	[opts.bankAccValue]
	 */
	this.fieldControls.setBankNameAllowBlankByBankAccValue = function ({ bankAccField, bankAccValue }) {
		const form = bankAccField?.up('form');
		const value = bankAccValue ?? bankAccField?.getValue();
		if (!form) {
			return;
		}
		let bankNameField = form.down('textfield[name=bank_name]');
		bankNameField.allowBlank = !value?.length;
		bankNameField.validate();
	};
	/**
	 * Switch address tab fields due to address type (Russian or Foreign)
	 * @param addressTypeCombo
	 */
	this.fieldControls.switchAddressBlocks = function (addressTypeCombo) {
		var companySelector = addressTypeCombo.modalInstance,
			form = companySelector ? companySelector.formPanel : null,
			isModalReadOnly = edi.utils.getObjectProperty(companySelector, 'modalConf.readOnly') || false,
			forceChangeFieldsMap = edi.utils.getObjectProperty(companySelector, 'forceChangeFields') || {},
			forceChangeAnyField = !!companySelector.forceChangeAnyField,
			value = addressTypeCombo.getValue(),
			org = companySelector ? companySelector.selectedOrgValues : null,
			isRussian = !value;

		if (!form) {
			return;
		}

		var rusCityField = form.down('textfield[name=addr_rus_city]');
		if (rusCityField) {
			var rusBlock = rusCityField.up('fieldset');
			if (rusBlock) {
				edi.selectors.utils.enableFieldBlock(
					rusBlock,
					isRussian,
					org,
					isModalReadOnly,
					forceChangeFieldsMap,
					forceChangeAnyField
				);
			}
		}

		var foreignAddr = form.down('textfield[name=addr_for_text]');
		if (foreignAddr) {
			var forBlock = foreignAddr.up('fieldset');
			if (forBlock) {
				edi.selectors.utils.enableFieldBlock(
					forBlock,
					!isRussian,
					org,
					isModalReadOnly,
					forceChangeFieldsMap,
					forceChangeAnyField
				);
			}
		}

		if (form) {
			form.isValid();
		}
	};
	this.COMMON_ORGANIZATION_ADDRESS = {
		category: this.defaults.category,
		country: Ext.merge(Ext.clone(this.defaults.iso2_country_combo), {
			maxLength: 140,
			fieldConfig: {
				mandatory: false
			}
		}),
		region: Ext.applyIf({ maxLength: 140 }, this.defaults.region),
		area: Ext.applyIf(
			{
				name: 'addr_area',
				maxLength: 140
			},
			this.defaults.area
		),
		city: Ext.applyIf({ maxLength: 140 }, this.defaults.city),
		zip: Ext.applyIf(
			{
				regex: edi.constants.VALIDATORS.ZIP_XSD,
				maxLength: 9
			},
			this.defaults.zip
		),
		street: Ext.applyIf({ maxLength: 255 }, this.defaults.street),
		locality: Ext.applyIf(
			{
				name: 'addr_locality',
				maxLength: 140
			},
			this.defaults.locality
		),
		home: Ext.applyIf({ maxLength: 20 }, this.defaults.home),
		block: Ext.applyIf(
			{
				name: 'addr_block',
				maxLength: 20
			},
			this.defaults.block
		),
		flat: Ext.applyIf({ maxLength: 20 }, this.defaults.flat),
		addInf: Ext.applyIf({ maxLength: 128 }, this.defaults.addInf)
	};
	this.mergeModalCfgWithCommonOrgAddress = function (source) {
		var tabs = Ext.clone(source);
		var tabs_adr = tabs?.tabs?.address;
		var address = Ext.merge({}, this.COMMON_ORGANIZATION_ADDRESS, tabs_adr);
		var dest = Ext.merge(tabs, { tabs: { address: address } });

		//reduce for save ordering after merge source data with default
		dest.tabs.address = Object.keys(this.COMMON_ORGANIZATION_ADDRESS).reduce(function (acc, key) {
			acc[key] = dest.tabs.address[key];
			return acc;
		}, {});
		return dest;
	};
	this.COMMON_FIELDS_MAP = [
		'addr_category',
		'addr_country',
		'addr_zip',
		'addr_locality',
		'addr_block',
		'addr_addInf',
		'addr_area',
		'addr_region_name',
		'addr_region_code',
		'addr_city',
		'addr_street',
		'addr_home',
		'addr_flat'
	];

	this.orgConfig = {
		/**
		 * {тип_организации: обязательное_поле}
		 * использовать для определения типа организации по наличию заполнения обязательного поля
		 */
		DEFAULT: [
			{ company: 'company_name' },
			{ individual: 'individual_inn' },
			{ person: 'person_inn' },
			{ legalForeigner: 'foreign_name' },
			{ individualParticipant: 'physical_firstname' },
			{ companyNotRegistered: 'notregistered_name' }
		],
		DEFAULT_NAMES: [
			'companyNotRegistered',
			'legalForeigner',
			'individual',
			'company',
			'individualParticipant',
			'person'
		]
	};
})();
